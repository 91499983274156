import { action, observable, computed } from "mobx";
import { RESTClient, LocalStorageClient } from "ks_storybook";
import { PlaylistLog, SongLog, AdLog } from "../models";
import {
  UIStore,
  UsersStore,
  CategoriesStore,
  CategoriesPlaylistsStore,
  UserGroupStore,
  ConexionsLogsStore,
  PlaylistsStore,
  PlaylistsPlansStore,
  AdsStore,
  AdsPlansStore,
  PreferencesStore,
  PlaylistLogStore,
  SongLogStore,
  AdLogStore,
  UserPlaylistsStore,
  UserPersonalizationStore,
  AdvertisingGuidelinesStore,
  UserSettingsStore,
  PPAdvertisementsStore,
  PPArrayClocksStore,
  PPClocksStore,
  PPCompaniesStore,
  PPMediaTypeStore,
  PPTrademarksStore,
  PPVersionsAdsStore,
  PPCalendarClocksStore,
  PPAdvertisementsLogStore,
  UsersStylesStore,
  SongsStore,
  SongStylesStore,
  UsersSongsEditStore,
  UsersPlaylistSongsStore,
  UsersBlocksStore,
  UserHibernationStore,
  KSConnectionLogStore,
  KSPermissionsStore,
  UserKSPermissionsStore,
  UserFavoritePlaylistsStore,
  SingersStore,
  UserBlockedSingersStore,
  AppConnectionHistoryStore,
  UsersSupervisorsStore,
} from ".";
import es_ES from "../lang/es_ES.js";
import en_EN from "../lang/en_EN.js";
import fr_FR from "../lang/fr_FR.js";
import pt_PT from "../lang/pt_PT.js";
import et_EE from "../lang/et_EE.js";
import de_DE from "../lang/de_DE.js";
import it_IT from "../lang/it_IT.js";

import TESTING from "../companies/testing.js";
import FUNBOXMUSIC from "../companies/funboxmusic.js";
import SMARTMCMUSIC from "../companies/smartmcmusic.js";
import MYSOUND from "../companies/mysonud.js";
import KASIMU from "../companies/kasimu.js";
import AUDIOPLAYER from "../companies/audioplayer.js";
import NEOMEDIA from "../companies/neomedia.js";
import BITPROXIMITY from "../companies/bitproximity.js"
import MOZARTPLAY from "../companies/mozartplay.js"
import MLBUISNESS from "../companies/monitorlatino.js";

import pkg from "../../package.json";
import KSConnectionLog from "../models/KSConnectionLog";

export default class AppStore {
  @observable isLoading = true;
  @observable loggedInUser = null;
  @observable supervisors = null;
  @observable permissions = [];
  @observable typeUser = 0;
  @observable catalog = -1;
  @observable serviceErrors = [];
  @observable lang = 2;
  @observable language = en_EN;
  @observable langSeeable = true;
  @observable viewMobile = false;
  @observable loggedInUserPersonalization = null;
  @observable loggedInLicenses = null;
  @observable toggleMenu = false;
  @observable typeView = 6;
  @observable companies = [
    KASIMU,
    AUDIOPLAYER,
    FUNBOXMUSIC,
    SMARTMCMUSIC,
    MYSOUND,
    KASIMU,
    AUDIOPLAYER,
    NEOMEDIA,
    BITPROXIMITY,
    MOZARTPLAY,
    MLBUISNESS
  ];
  @observable currentCompany = AUDIOPLAYER;
  @observable autoUsername = "";
  @observable autoPws = "";
  @observable version = `v ${pkg.version}`;
  @observable menuTitle = "";

  api_token_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_token`;
  logged_user_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_user`;

  @observable failsafeMode = false;
  refreshTime = 60000;

  settings = {
    refreshRate: 5, // in minutes
  };

  getLogo() {
    return this.currentCompany.logo;
  }

  constructor() {
    const storedToken = localStorage.getItem(this.api_token_key);
    const storedUser = localStorage.getItem(this.logged_user_key);
    const storedTypeUser = localStorage.getItem("currentTypeUser");
    const storedCatalog = localStorage.getItem("currentCatalog");
    const storedSupervisors = localStorage.getItem("currentSupervisors");
    const storedLang = localStorage.getItem("currentLang");
    const storedTypeView = localStorage.getItem("currentTypeView");

    // create adapters
    this.kasimuAPIClient = new RESTClient(
      process.env.REACT_APP_KASIMU_API_URL,
      storedToken
    );
    this.localStorageClient = new LocalStorageClient("ks");

    // initialize stores
    this.stores = new Map();

    // Domain stores
    this.stores.set("users", new UsersStore(this.kasimuAPIClient, this));
    this.stores.set(
      "categories",
      new CategoriesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "categoriesPlaylists",
      new CategoriesPlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("groups", new UserGroupStore(this.kasimuAPIClient, this));
    this.stores.set(
      "conexionsLogs",
      new ConexionsLogsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ksConnectionLog",
      new KSConnectionLogStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlists",
      new PlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlistsPlans",
      new PlaylistsPlansStore(this.kasimuAPIClient, this)
    );
    this.stores.set("adsPlans", new AdsPlansStore(this.kasimuAPIClient, this));
    this.stores.set("ads", new AdsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "preferences",
      new PreferencesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlistLog",
      new PlaylistLogStore(this.kasimuAPIClient, this)
    );
    this.stores.set("songLog", new SongLogStore(this.kasimuAPIClient, this));
    this.stores.set("adLog", new AdLogStore(this.kasimuAPIClient, this));
    this.stores.set(
      "userPlaylists",
      new UserPlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userPersonalization",
      new UserPersonalizationStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "advertisingGuidelines",
      new AdvertisingGuidelinesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userSettings",
      new UserSettingsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppAds",
      new PPAdvertisementsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppCompanies",
      new PPCompaniesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppMediaType",
      new PPMediaTypeStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppTrademarks",
      new PPTrademarksStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppVersionsAds",
      new PPVersionsAdsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("ppClocks", new PPClocksStore(this.kasimuAPIClient, this));
    this.stores.set(
      "ppCalendarClocks",
      new PPCalendarClocksStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppArrayClocks",
      new PPArrayClocksStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ppAdsLog",
      new PPAdvertisementsLogStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersStyles",
      new UsersStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set("songs", new SongsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "songStyles",
      new SongStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersSongsEdit",
      new UsersSongsEditStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersPlaylistSongs",
      new UsersPlaylistSongsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersBlocks",
      new UsersBlocksStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userHibernation",
      new UserHibernationStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "ksPermissions",
      new KSPermissionsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userKSPermissions",
      new UserKSPermissionsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userFavoritePlaylists",
      new UserFavoritePlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("singers", new SingersStore(this.kasimuAPIClient, this));
    this.stores.set(
      "userBlockedSingers",
      new UserBlockedSingersStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "appConnectionHistory",
      new AppConnectionHistoryStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersSupervisors",
      new UsersSupervisorsStore(this.kasimuAPIClient, this)
    );

    // UI stores
    this.stores.set("ui", new UIStore(this.localStorageClient, this));

    // create easy stores getters
    this.stores.forEach((store, key) => {
      Object.defineProperty(this, key, {
        get: (v) => store,
      });

      store.updateThreshold = this.settings.refreshRate;
    });

    this.lang = parseInt(storedLang) || this.lang;
    this.getLanguage(this.lang);

    // is already a session open?
    if (storedUser > 0) {
      this.users.get(storedUser).andThen((res, err) => {
        if (err) {
          // something went terrible wrong....
          this.typeView = parseInt(storedTypeView);
          this.currentCompany = this.companies[this.typeView];
          this.signOut();
          this.isLoading = false;
        } else {
          this.supervisors = JSON.parse(storedSupervisors);
          this.loadPermissions().then(() => {
            this.setCurrentUser(res);
            this.typeUser = parseInt(storedTypeUser);
            this.catalog = parseInt(storedCatalog);

            this.isLoading = false;
            this.langSeeable = false;
            this.typeView = parseInt(storedTypeView);
            this.currentCompany = this.companies[this.typeView];
            // this.lang = parseInt(storedLang);
            // this.getLanguage(this.lang);
            this.loadUserPersonalization();
            this.loadUserLicenses();
          });
        }
      });
    } else {
      this.typeView = parseInt(storedTypeView);
      this.supervisors = JSON.parse(storedSupervisors);
      this.currentCompany = this.companies[this.typeView];
      this.signOut();
      this.isLoading = false;
    }
  }

  @action
  signOut() {
    this.autoUsername = "";
    this.autoPws = "";
    this.langSeeable = true;
    if (this.loggedInUser != null) {
      const connectionLog = this.newConnectionLog(2);
      connectionLog.save();
    }

    this.kasimuAPIClient.token = null;
    this.loggedInUser = null;
    this.supervisors = null;
    localStorage.setItem("currentSupervisors", this.supervisors);
    this.ui.signOut();
    this.stores.forEach((store, key) => {
      store.clear();
    });

    localStorage.setItem(this.api_token_key, null);
    localStorage.setItem(this.logged_user_key, null);
    // localStorage.setItem("currentLang", 1);
  }

  @action
  signIn(user, password) {
    localStorage.setItem("currentLang", this.lang);
    this.setTypeView(this.typeView);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("username", user);
    urlencoded.append("password", password);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      this.kasimuAPIClient.apiURI + "/login_web_panel",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.answer_code == 3) {
          this.kasimuAPIClient.token = result.token;
          this.setCurrentUser(this.users.store(result.user));
          // save the info in storage
          localStorage.setItem(this.api_token_key, this.kasimuAPIClient.token);
          localStorage.setItem(this.logged_user_key, this.loggedInUser.id);
          localStorage.setItem("currentTypeUser", result.type_user);
          localStorage.setItem("currentCatalog", result.catalog);
          this.typeUser = result.type_user;
          this.catalog = result.catalog;
          if (result.supervisors) {
            this.setCurrentSupervisors(result.supervisors);
          } else {
            this.supervisors = null;
          }
          const connectionLog = this.newConnectionLog(1);
          connectionLog.save();
          this.loadUserPersonalization();
          this.loadUserLicenses();
          this.langSeeable = false;
        }
        return result;
      })
      .catch((error) => {
        let res = { answer_code: 11 };
        return res;
      });
  }

  @action
  setTypeView(type) {
    localStorage.setItem("currentTypeView", type);
    this.typeView = type;
    this.currentCompany = this.companies[this.typeView];
  }

  @action
  loadTypeView() {
    if (isNaN(this.typeView)) {
      this.typeView = 1;
    }
    localStorage.setItem("currentTypeView", this.typeView);
    this.currentCompany = this.companies[this.typeView];
  }

  @action
  loadUserPersonalization = () => {
    const x = this.userPersonalization.fetchAll();
    x.andThen((response) => {
      if (response.length > 0) {
        let res = response.toArray();
        this.setCurrentUserPersonalization(
          this.userPersonalization.store(res[0])
        );
      } else {
        this.setCurrentUserPersonalization(null);
      }
    });
  };

  @action
  loadUserLicenses = () => {
    this.kasimuAPIClient.search("/users/get_info_licenses", {}).then((res) => {
      this.setCurrentUserLicenses(res.results);
    });
  };

  @action
  getVersion = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      process.env.REACT_APP_KASIMU_API_URL + "/get_version/?id=" + id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("error", error));
  };

  @action
  setCurrentUser(user) {
    this.loggedInUser = user;
  }

  @action
  setCurrentUserPersonalization(personalization) {
    this.loggedInUserPersonalization = personalization;
  }

  @action
  setCurrentUserLicenses(licenses) {
    this.loggedInLicenses = licenses;
  }

  @computed
  get isLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser != null;
  }

  @computed
  get isLoggingIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser == null;
  }

  @computed
  get loggedInUserKey() {
    if (this.isLoggedIn) {
      return this.loggedInUser.id;
    }

    return null;
  }

  getCurrentTheme() {
    return "kasimu";
  }

  // @action
  getLanguage(val) {
    if (val == 2) {
      localStorage.setItem("currentLang", val);
      return (this.language = en_EN);
    }
    if (val == 1) {
      localStorage.setItem("currentLang", val);
      return (this.language = es_ES);
    }
    if (val == 3) {
      localStorage.setItem("currentLang", val);
      return (this.language = fr_FR);
    }
    if (val == 4) {
      localStorage.setItem("currentLang", val);
      return (this.language = pt_PT);
    }
    if (val == 5) {
      localStorage.setItem("currentLang", val);
      return (this.language = et_EE);
    }
    if (val == 6) {
      localStorage.setItem("currentLang", val);
      return (this.language = de_DE);
    }
    if (val == 7) {
      localStorage.setItem("currentLang", val);
      return (this.language = it_IT);
    }
  }

  getFreeRights() {
    return this.catalog;
  }

  getSupervisors() {
    return this.supervisors;
  }

  getSupervisorsGroupId() {
    if (this.supervisors) {
      return parseInt(this.supervisors.group_id.replace(/[\[\]]/g, ""), 10);
    }
    return null;
  }

  replaceBucketUrl(originalUrl) {
    if (originalUrl) {
      // URL base a reemplazar
      let oldBaseUrl =
        process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
          ? "https://bucket-kasimu.s3.amazonaws.com/"
          : "https://bucket-kasimu-test.s3.amazonaws.com/";

      // Nueva URL base
      let newBaseUrl = this.getBucketUrl();

      // Reemplazar la URL base en la URL original
      let newUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);

      // Devolver la nueva URL
      return newUrl;
    }
    return null;
  }

  getBucketUrl() {
    return process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
      ? "https://sources.megaplayer.me/"
      : "https://sources-test.megaplayer.me/";
  }

  getProduction() {
    return process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
      ? 1
      : 2;
  }

  getKasimuCategorie() {
    //Agregar cada vez que se agrega un catalogo
    if (this.catalog == -1 || this.catalog == null) return 0;
    const categorie = [24, 32, 43, 46];
    let cat = categorie[this.catalog];
    return cat;
  }

  getServiceName() {
    //Agregar cada vez que se agrega un catalogo
    const title = ["Global", "Freedom 1", "Freedom 2", "Freedom 3"];
    let name = this.currentCompany.name + " ";
    if (this.getUserRadio()) name = name + "Ads " + title[this.catalog];
    else name = name + title[this.catalog];

    return name;
  }

  getUserRadio() {
    switch (this.loggedInUser.suscription.productos_id) {
      case 67:
        return true;
      case 68:
        return true;
      default:
        return false;
    }
  }

  getSingleUser() {
    if (this.typeUser == 1) return false;
    return true;
  }

  currentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minutes = today.getMinutes();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd + " " + hour + ":" + minutes;
    return today;
  }

  newConnectionLog(connectionType) {
    return new KSConnectionLog(
      {
        connection_type: connectionType,
        string_version: pkg.version.split(".").splice(1, 2).join("."),
        platforms: 1,
        deleted: 0,
      },
      this.ksConnectionLog
    );
  }

  newPlaylistLog(categoriesPlaylistId) {
    const today = this.currentDate();
    const preference = this.loggedInUser.configs;
    preference.last_categories_playlist_id = categoriesPlaylistId;
    preference.save();
    return new PlaylistLog(
      {
        playlist_id: categoriesPlaylistId,
        play_date: today,
        playlist_type: 1,
        deleted: 0,
      },
      this.playlistLog
    );
  }

  newSongLog(songId) {
    const today = this.currentDate();
    return new SongLog(
      {
        song: songId,
        play_date: today,
        addedby: 1,
        deleted: 0,
      },
      this.songLog
    );
  }

  newAdLog(adPlan) {
    const today = this.currentDate();
    return new AdLog(
      {
        advertisements_id: adPlan.advertisements_id,
        week_day: adPlan.week_day,
        exact_day: adPlan.exact_day,
        exact_hour: adPlan.exact_hour,
        from_hour: adPlan.from_hour,
        to_hour: adPlan.to_hour,
        frequency: adPlan.frequency,
        priority: adPlan.priority,
        play_date: today,
        duration: 0,
        deleted: 0,
      },
      this.adLog
    );
  }

  arrayObjToCsv(ar, name) {
    //comprobamos compatibilidad
    if (window.Blob && (window.URL || window.webkitURL)) {
      var contenido = "",
        d = new Date(),
        blob,
        reader,
        save,
        clicEvent;
      //creamos contenido del archivo
      for (var i = 0; i < ar.length; i++) {
        //construimos cabecera del csv
        if (i == 0) contenido += Object.keys(ar[i]).join(";") + "\n";
        //resto del contenido
        contenido +=
          Object.keys(ar[i])
            .map(function (key) {
              return ar[i][key];
            })
            .join(";") + "\n";
      }
      //creamos el blob
      blob = new Blob(["\ufeff", contenido], { type: "text/csv" });
      //creamos el reader
      var reader = new FileReader();
      reader.onload = function (event) {
        //escuchamos su evento load y creamos un enlace en dom
        save = document.createElement("a");
        save.href = event.target.result;
        save.target = "_blank";
        //aquí le damos nombre al archivo
        save.download =
          name +
          "_" +
          d.getDate() +
          "_" +
          (d.getMonth() + 1) +
          "_" +
          d.getFullYear() +
          ".csv";
        try {
          //creamos un evento click
          clicEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
        } catch (e) {
          //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
          clicEvent = document.createEvent("MouseEvent");
          clicEvent.initEvent("click", true, true);
        }
        //disparamos el evento
        save.dispatchEvent(clicEvent);
        //liberamos el objeto window.URL
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      };
      //leemos como url
      reader.readAsDataURL(blob);
    } else {
      //el navegador no admite esta opción
      alert("Su navegador no permite esta acción");
    }
  }

  convertToCSV(objArray) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ";";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportCSVFile(headers, items, fileName) {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  showContent(name) {
    switch (name) {
      case "kasimu": {
        const valid = {
          0: true,
          1: false,
          2: false,
          3: false,
          4: false,
        };

        return valid[this.typeView] || false;
      }
    }
  }

  @action
  setCurrentSupervisors(supervisors) {
    // this.supervisors = JSON.stringify(supervisors);
    localStorage.setItem("currentSupervisors", JSON.stringify(supervisors));
    this.supervisors = supervisors;
  }

  @action
  loadPermissions = () => {
    var that = this;
    let arr = [];
    return new Promise((resolve, reject) => {
      if (that.supervisors) {
        that.ksPermissions
          .fetchAllBySupervisor(that.supervisors.id, 1)
          .andThen((permissions) => {
            let perms = permissions.toArray();
            for (let i = 0; i < perms.length; i++) {
              arr.push(perms[i].toJS());
            }
            that.supervisors.permissions = arr;
            that.setCurrentSupervisors(that.supervisors);
            resolve(that.supervisors);
          });
      } else {
        resolve(that.supervisors);
      }
    });
  };

  getMessagePermission(id) {
    const message = {
      23: this.language.label789,
      24: this.language.label790,
      25: this.language.label791,
      26: this.language.label792,
      27: this.language.label793,
      28: this.language.label794,
      29: this.language.label795,
      30: this.language.label796,
      31: this.language.label797,
      32: this.language.label798,
      33: this.language.label799,
      34: this.language.label800,
      35: this.language.label801,
      36: this.language.label802,
      37: this.language.label803,
      38: this.language.label804,
      39: this.language.label805,
      40: this.language.label806,
      41: this.language.label807,
      42: this.language.label808,
      43: this.language.label809,
      44: this.language.label810,
      45: this.language.label811,
      46: this.language.label812,
      47: this.language.label813,
      48: this.language.label814,
    };
    const msg = message[id];

    if (msg) {
      this.toastManager.add(msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  getPermission(id, msg, fc) {
    const permission = {
      0: () => this.getMessagePermission(msg),
      1: () => fc(),
    };
    return permission[this.validPermission(id)]();
  }

  validPermission(id) {
    let typeAccess = 0;
    if (this.supervisors) {
      const permission = this.supervisors.permissions.find(
        (per) => per.id === id
      );
      if (permission) {
        if (permission.user_permission.permission_activated) typeAccess = 1;
        else typeAccess = 0;
      } else typeAccess = 1;
    } else typeAccess = 1;

    return typeAccess;
  }

  verifyPermission(id) {
    let typeAccess = true;
    if (this.supervisors) {
      const permission = this.supervisors.permissions.find(
        (per) => per?.id == id
      );
      if (permission) {
        if (permission?.user_permission.permission_activated) typeAccess = true;
        else typeAccess = false;
      } else typeAccess = false;
    }
    return typeAccess;
  }
}
